export const WORDS = [
'w',
'y',
'a',
'e',
'i',
'o',
'u',
's',
'm',
't',
'd',
'b',
'f',
'h',
'c',
'g',
'j',
'k',
'q',
'l',
'n',
'p',
'r',
'x',
'z',
'v',
]
